import { camelCase, startCase } from 'lodash-es';

const EventName = {
    unknown: 'UNKNOWN'
} as const;
const Suffix = {
    readableLabel: 'Label'
} as const;

export function logEvent(params: ILogEvent) {
    const { EV_LOGGER: logger } = window;
    const { eventName = EventName.unknown, attributes = {}, readableLabels = [] } = params;
    const updatedAttributes = getUpdatedAtributes({ attributes, readableLabels });

    logger?.api?.pushEvent?.(eventName, updatedAttributes);
}

function getUpdatedAtributes({ attributes = {}, readableLabels = [] }: Pick<ILogEvent, 'attributes' | 'readableLabels'>) {
    const updatedAttributes = {
        ...attributes
    };

    let readableLabelKey: string;

    for (const readableLabel of readableLabels) {
        if (attributes[readableLabel]) {
            readableLabelKey = camelCase(`${readableLabel}${Suffix.readableLabel}`);

            updatedAttributes[readableLabelKey] = startCase(attributes[readableLabel]);

            console.log({ readableLabelKey,  });
        }
    }

    return updatedAttributes;
}

interface ILogEvent {
    eventName: string;
    attributes: Record<string, string>,
    readableLabels?: string[];
}